import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TurneySlider = (props) => {

    const [scrWidth, setScrWidth] = useState(window.screen.width);
    const [scrHeight, setScrHeight] = useState(window.screen.height);

    useEffect(() => {
        const handleResize = () => {
            setScrWidth(window.screen.width);
            setScrHeight(window.screen.height);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    });

    const renderSlider = () => {
        const { main, type } = props;
        const { esport } = main;

        if (esport) {
            return esport.filter((slider) => {
                return slider.type === type;
            }).map((slider, key) => {
                if (slider.status === "active" ) {
                    return (
                        <a href={slider.url} target="_blank" rel="noopener noreferrer" key={key}>
                            <div className="slider-items">
                                <img src={slider.img_url} alt={slider.status} className="slide-img" />
                                <div className="link-mobile">{ slider.status === "active" ? "IKUTI EVENTNYA" : "SEGERA HADIR" }</div>
                                <div className="overlay">
                                    <span className="link-desktop">{ slider.status === "active" ? "IKUTI EVENTNYA" : "SEGERA HADIR" }</span>
                                </div>
                            </div>
                        </a>
                    );
                } else {
                    return (
                        <div key={key}>
                            <div className="slider-items">
                                <img src={slider.img_url} alt={slider.status} className="slide-img" />
                                <div className="link-mobile">{ slider.status === "active" ? "IKUTI EVENTNYA" : "SEGERA HADIR" }</div>
                                <div className="overlay">
                                    <span className="link-desktop">{ slider.status === "active" ? "IKUTI EVENTNYA" : "SEGERA HADIR" }</span>
                                </div>
                            </div>
                        </div>
                    );
                }
            });
        }
    }

    const settings = {
        slidesToShow: scrWidth > scrHeight ? 4 : 2,
        slidesToScroll: 1,
        draggable: true,
        infinite: false
    }

    return (
        <div className="slider-wrapper">
            <Slider { ...settings }>
                { renderSlider() }
            </Slider>
        </div>
    );

}

export default TurneySlider;