import React, { Component } from "react";
import "../helper/component/navbar/css/hamburger.css";

class Navbar extends Component {

    state = {
        nav_open: false,
        dropdown_open: false
    }

    toggle_nav = (nav_open) => {
        this.setState({
            nav_open: nav_open
        });
    }

    toggle_dropdown = (id) => {
        this.state.dropdown_open === id ? this.setState({ dropdown_open: "" }) : this.setState({ dropdown_open: id });
    }

    render() {
        const { nav_open, dropdown_open } = this.state;
        return (
            <nav className="navbar">
                {/* Desktop Navigation */}
                <div className="desktop-nav">
                    <div className="nav-logo">
                        <a href="https://main.codm.garena.co.id/" target="_blank" rel="noopener noreferrer">
                            <img src={require("../assets/img/logos/Logo-CODM.png")} alt="logo" className="img-fluid" />
                        </a>
                    </div>
                    <ul className="nav-item">
                        <li className="dropdown">
                            <a href="https://main.codm.garena.co.id/guide/mode/team_deathmatch" target="_blank" rel="noopener noreferrer">
                                GUIDE
                            </a>
                            <ul className="dropdown-item">
                                <li>
                                    <a href="https://main.codm.garena.co.id/guide/mode/team_deathmatch" target="_blank" rel="noopener noreferrer">
                                        Mode
                                    </a>
                                </li>
                                <li>
                                    <a href="https://main.codm.garena.co.id/guide/feature/scorestreak" target="_blank" rel="noopener noreferrer">
                                        Fitur Unik
                                    </a>
                                </li>
                                <li>
                                    <a href="https://main.codm.garena.co.id/guide/character" target="_blank" rel="noopener noreferrer">
                                        Karakter
                                    </a>
                                </li>
                                <li>
                                    <a href="https://main.codm.garena.co.id/guide/maps" target="_blank" rel="noopener noreferrer">
                                        Maps
                                    </a>
                                </li>
                                <li>
                                    <a href="https://main.codm.garena.co.id/guide/weapons/assault_rifle" target="_blank" rel="noopener noreferrer">
                                        Senjata
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="dropdown">
                            <a href="https://main.codm.garena.co.id/news/all" target="_blank" rel="noopener noreferrer">
                                BERITA
                            </a>
                            <ul className="dropdown-item">
                                <li>
                                    <a href="https://main.codm.garena.co.id/news/all" target="_blank" rel="noopener noreferrer">
                                        Semua
                                    </a>
                                </li>
                                <li>
                                    <a href="https://main.codm.garena.co.id/news/update" target="_blank" rel="noopener noreferrer">
                                        Update
                                    </a>
                                </li>
                                <li>
                                    <a href="https://main.codm.garena.co.id/news/event" target="_blank" rel="noopener noreferrer">
                                        Event
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="https://fairplay.codm.garena.co.id/" target="_blank" rel="noopener noreferrer">
                                FAIRPLAY
                            </a>
                        </li>
                        <li>
                            <a href="https://support.garena.co.id/home" target="_blank" rel="noopener noreferrer">
                                SUPPORT
                            </a>
                        </li>
                        <li>
                            <a href="https://esports.codm.garena.com/" target="_blank" rel="noopener noreferrer">
                                ESPORTS
                            </a>
                        </li>
                    </ul>
                </div>

                {/* Mobile Navigation */}
                <div className="mobile-nav">
                    <div className="main-nav">
                        <div className={`hamburger hamburger--spin ${nav_open ? `is-active` : null}`} onClick={() => this.toggle_nav(!nav_open)}>
                            <span className="hamburger_box hamburger-box">
                                <span className="hamburger_inner hamburger-inner"></span>
                            </span>
                        </div>
                        <div className="nav-logo">
                            <img src={require("../assets/img/logos/Logo-CODM.png")} alt="logo" />
                        </div>
                    </div>
                    <div className={`overlay-menu ${nav_open ? `active` : null}`}>
                        <div className={`menu ${nav_open ? `active` : null}`}>
                            <ul className="menu-item">
                                <li className={`collapse ${dropdown_open === `guide` ? `open` : ``}`} id="guide"
                                    onClick={(e) => this.toggle_dropdown(e.currentTarget.id)}>
                                    <span>GUIDE</span>
                                    <div className="collapse-content">
                                        <ul className="collapse-menu">
                                            <li>
                                                <a href="https://main.codm.garena.co.id/guide/mode/team_deathmatch" target="_blank" rel="noopener noreferrer">Mode</a>
                                            </li>
                                            <li>
                                                <a href="https://main.codm.garena.co.id/guide/feature/scorestreak" target="_blank" rel="noopener noreferrer">Fitur Unik</a>
                                            </li>
                                            <li>
                                                <a href="https://main.codm.garena.co.id/guide/character" target="_blank" rel="noopener noreferrer">Karakter</a>
                                            </li>
                                            <li>
                                                <a href="https://main.codm.garena.co.id/guide/maps" target="_blank" rel="noopener noreferrer">Maps</a>
                                            </li>
                                            <li>
                                                <a href="https://main.codm.garena.co.id/guide/weapons/assault_rifle" target="_blank" rel="noopener noreferrer">Senjata</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className={`collapse ${dropdown_open === `berita` ? `open` : ``}`} id="berita"
                                    onClick={(e) => this.toggle_dropdown(e.currentTarget.id)}>
                                    <span>BERITA</span>
                                    <div className="collapse-content">
                                        <ul className="collapse-menu">
                                            <li>
                                                <a href="https://main.codm.garena.co.id/news/all" target="_blank" rel="noopener noreferrer">Semua</a>
                                            </li>
                                            <li>
                                                <a href="https://main.codm.garena.co.id/news/update" target="_blank" rel="noopener noreferrer">Update</a>
                                            </li>
                                            <li>
                                                <a href="https://main.codm.garena.co.id/news/event" target="_blank" rel="noopener noreferrer">Event</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="https://fairplay.codm.garena.co.id/" target="_blank" rel="noopener noreferrer">FAIRPLAY</a>
                                </li>
                                <li>
                                    <a href="https://support.garena.co.id/home" target="_blank" rel="noopener noreferrer">SUPPORT</a>
                                </li>
                                <li>
                                    <a href="https://esports.codm.garena.com/" target="_blank" rel="noopener noreferrer">ESPORTS</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }

}

export default Navbar;