import React from 'react';
import TurneySlider from "../partial/TurneySlider";

class Home extends React.Component {

    state = {
        tabs: "official_tournament"
    }

    changeTabs = (tabs_name) => {
        this.setState({
            tabs: tabs_name
        });
    }

    render() {
        const { tabs } = this.state;

        return (
            <div className="home">
                <div className="title-container">
                    <div className="title">
                        <img src={require("../assets/img/logos/Logo_M.png")} alt="codm" />
                        <span>KOMPETISI ESPORTS</span>
                    </div>
                    <div className="sub-title">
                        <span>CALL OF DUTY MOBILE</span>
                    </div>
                </div>

                <div className="nav-tabs">
                    <div className={`tabs-item ${tabs === `official_tournament` ? `active` : ``}`} onClick={() => this.changeTabs("official_tournament")}>
                        <span><b>OFFICIAL</b> TOURNAMENT</span>
                    </div>
                    <div className={`tabs-item ${tabs === `community_tournament` ? `active` : ``}`} onClick={() => this.changeTabs("community_tournament")}>
                        <span><b>COMMUNITY</b> TOURNAMENT</span>
                    </div>
                </div>

                <TurneySlider { ...this.props } type={tabs} />
            </div>
        );
    }

}

export default Home;