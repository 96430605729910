import React, { Component } from "react";

class Footer extends Component {

    render() {
        return (
            <div className="footer">
                <div className="footer-cont">
                    <div className="logos">
                        <img src={require("../assets/img/logos/Logo_Garena.png")} alt="garena" />
                        <img src={require("../assets/img/logos/Logo_act.png")} alt="activision" />
                        <img src={require("../assets/img/logos/Logo_Timi.png")} alt="timi" />
                    </div>
                    <div className="divider"></div>
                    <p>Copyright © Garena Online. Copyright © Tencent. All rights reserved. © 2019 Activision Publishing, Inc. ACTIVISION and CALL OF DUTY are of Activision Publishing, Inc.Trademarks belong to their respective owners. All rights reserved.</p>
                </div>
            </div>
        )
    }

}

export default Footer;