import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// Pages
import Home from 'pages/Home';
import ErrorBoundary from 'ErrorBoundary';

// Partials
import Navbar from 'partial/Navbar';
import Footer from 'partial/Footer';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

const NoMatch = () => (
    <div>404 Not Found</div>
);

class Main extends Component {

	componentDidMount() {
		this.props.actionsMain.get_esports_data();
	}

    render() {
        return (
            <Router basename={process.env.PUBLIC_URL}>
				<div id="Main" className="main-panel">
					<ErrorBoundary>
						<Navbar />
						<div className="content-container">
							<Switch>
								<Route exact path="/" component={() => <Home {...this.props} />} />
								<Route component={NoMatch} />
							</Switch>
						</div>
						<Footer />
					</ErrorBoundary>
				</div>
			</Router>
        );
    }
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);